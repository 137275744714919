
import { ref, defineComponent } from "vue";
import logopic from "@/assets/logo.png";

export default defineComponent({
  name: "HeaderComponent",
  setup() {
    const picurl = ref(
      // "https://img2.baidu.com/it/u=2854267156,2467496024&fm=253&fmt=auto&app=138&f=JPEG?w=359&h=359"
      logopic
    );
    return {
      picurl,
    };
  },
});

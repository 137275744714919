import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VideoPlayer from "vue-video-player/src";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
// import axios from "axios";
//import * as THREE from "three";

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(VideoPlayer)
  // .use(axios)
  .mount("#app");

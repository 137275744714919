
import { defineComponent } from "vue";
import PCHeaderComponent from "@/components/PCHeaderComponent.vue"; // @ is an alias to /src
import FooterComponent from "@/components/FooterComponent.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    PCHeaderComponent,
    FooterComponent,
  },
  mounted() {
    const availScreenWidth = window.screen.availWidth;
    let headerComponent;
    if (this._isMobile()) {
      console.log("手机端");
      // headerComponent = FooterComponent;
    } else {
      console.log("PC端," + availScreenWidth);
      // headerComponent = HeaderComponent;
    }
  },
  methods: {
    _isMobile() {
      return /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);
    },
  },
});

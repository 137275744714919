import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      headerShow: true,
      footerShow: true,
    },
  },
  {
    path: "/adventure",
    name: "adventure",
    component: () => import("../views/AdventureView.vue"),
    meta: {
      headerShow: true,
      footerShow: false,
    },
  },
  {
    path: "/goods",
    name: "goods",
    component: () => import("../views/GoodsView.vue"),
    meta: {
      headerShow: true,
      footerShow: true,
    },
  },
  {
    path: "/serve",
    name: "serve",
    component: () => import("../views/ServeView.vue"),
    meta: {
      headerShow: true,
      footerShow: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      headerShow: true,
      footerShow: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PCHeaderComponent = _resolveComponent("PCHeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.meta.headerShow)
      ? (_openBlock(), _createBlock(_component_PCHeaderComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.$route.meta.footerShow)
      ? (_openBlock(), _createBlock(_component_FooterComponent, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}